import React from "react";
import Layout from "../components/layout";
import {Row, Col} from "react-bootstrap"
import SEO from "../components/seo";


const About1 = () => (
    <Layout>
        <SEO title="Quiénes somos" description="Valores, misión y visión de CoopGestión"  />
        <Row className="pt-5 m-3 text-justify">
            <Col>
                <h2>
                    Misión
                </h2>
                <p>
                    La cooperativa de Ahorro y Crédito Gestión para el desarrollo
                    logrará un espacio y se consolidará en el sistema cooperativista
                    del país, permitiendo a través de sus productos y servicios el 
                    bienestar de sus socios para lograr el desarrollo y crecimiento de
                    nuestro país.
                </p>
            </Col>
        </Row>
        <Row className="py-3 m-3 text-justify">
            <Col>
                <h2>
                    Visión
                </h2>
                <p>
                    Apoyar a nuestros asociados con créditos accesibles para que puedan
                    utilizar los resultados en su desarrollo y progreso, contando con las
                    herramientas necesarias para un apoyo personalizado e incondicional de
                    nuestro recurso humano, con estos socios lograrán plasmar sus objetivos,
                    contando con recursos materiales y tecnológicos que respalden nuestro
                    trabajo, imágen y servicio.
                </p>
            </Col>
        </Row>
        <Row className="pb-5 m-3 text-justify">
            <Col>
                <h2>
                    Valores
                </h2>
                <ul>
                    <li>Productos y servicios de calidad</li>
                    <li>Tasas atractivas</li>
                    <li>Agilidad en los servicios</li>
                    <li>Facilidad de pago de los créditos</li>
                    <li>Atención personalizada</li>
                    <li>Créditos oportunos e inmediatos</li>
                </ul>
            </Col>
        </Row>
    </Layout>
);

export default About1;